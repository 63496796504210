
























































































import { contactPeople, currentAddress } from '@/composables/consents'
import { ConsentContactPerson } from '@/models/consent-contact-person'
import { computed, defineComponent, ref } from '@vue/composition-api'
import ModalContactPerson from './ModalContactPerson.vue'

export default defineComponent({
  components: { ModalContactPerson },
  setup() {
    const contactToEdit = ref<ConsentContactPerson | null>(null)
    const contactToDelete = ref<ConsentContactPerson | null>(null)
    const contactPeopleByEan = computed(() =>
      contactPeople.value.filter((o) => currentAddress.value?.generatedId === o.addressId),
    )

    return {
      isModalContactVisible: false,
      contactPeople: contactPeopleByEan,
      contactToEdit,
      contactToDelete,
    }
  },
  methods: {
    showModalContact() {
      this.contactToEdit = null
      this.isModalContactVisible = true
    },
    editContact(contact: ConsentContactPerson) {
      this.contactToEdit = contact
      this.isModalContactVisible = true
    },
    deleteContact(contact: ConsentContactPerson) {
      contactPeople.value = contactPeople.value.filter((o) => o !== contact)
    },
  },
})
