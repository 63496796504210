


















































import { contactPeople, currentAddress } from '@/composables/consents'
import { ConsentContactPerson } from '@/models/consent-contact-person'
import { computed, defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    open: { type: Boolean, default: false },
    closeable: String,
    contactToEdit: {
      type: Object as PropType<ConsentContactPerson>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const contact = ref<ConsentContactPerson>({} as ConsentContactPerson)
    const isEmailRequired = computed(() => !contact.value.mobileNumber)
    const isMobileNumberRequired = computed(() => !contact.value.email)

    // Reset the validation state when opening/closing the modal
    const { open } = toRefs(props)
    const observer = ref(null)
    watch(open, (val, old) => {
      if (observer.value) {
        (observer.value as any).reset()
      }
    })

    // Apply data when editing an existing contact
    const { contactToEdit } = toRefs(props)
    watch(contactToEdit, (val, old) => {
      contact.value = val ?? {}
    })

    return {
      contact,
      isEmailRequired,
      isMobileNumberRequired,
      observer,
    }
  },
  methods: {
    async addContact() {
      const isValid = await (this.observer as any).validate()
      if (!isValid) {
        return
      }
      // TODO: avoid dupes
      if (this.contact) {
        this.contact.addressId = currentAddress.value!.generatedId
        if (this.contactToEdit) {
          const i = contactPeople.value.indexOf(this.contactToEdit)
          contactPeople.value[i] = this.contact
        } else {
          contactPeople.value.push(this.contact)
        }
      }
      this.close()
    },
    close() {
      this.$emit('update:open', false)
    },
  },
})
