import { ConsentsForAddress, GridAccessPoint } from '@/models/api'
import { computed, ref, watch, watchEffect } from '@vue/composition-api'
import { i18n } from '@/i18n'
import { GridUser } from '@/models/api/gridUser'
import { ConsumptionPlace } from '@/models/api/consumptionPlace'
import { ConsentContactPerson } from '@/models/consent-contact-person'
const { t } = i18n

// API
export const token = ref<null | string>(null)

// Form progression / Breadcrumbs
export const canContinueForm = ref(false)
export const currentStep = ref(1)
export const lastStepSeen = ref(1)
export function goToStep(step: number) {
  currentStep.value = step
  lastStepSeen.value = step
  canContinueForm.value = false
}
export function goNextStep() {
  goToStep(currentStep.value + 1)
}

// Authentication data
const isDev = process.env.VUE_APP_ENV === 'development'
export const eanCode = ref(isDev ? '541449020713086941' : null)
export const lastFourMeterNumbers = ref(isDev ? '8766' : null)

// User data
export const gridAccessPoint = ref<GridAccessPoint | null>(null)
export const gridUser = ref<GridUser | null>(null)
export const consents = ref<ConsentsForAddress[]>([])
export const consumptionAddresses = ref<ConsumptionPlace[]>([])

// Currently selected Address
export const currentAddress = ref<ConsumptionPlace | null>(null)
watch(consumptionAddresses, (val) => {
  if (val.length) {
    currentAddress.value = val[0]
  }
})

// Checks depending on email/phone information
export const canCheckEmailConsents = computed(() => !!gridUser.value?.contact.email)
export const canCheckSmsConsents = computed(() => !!gridUser.value?.contact.mobile)
export const canCheckDigitalLetter = computed(() => canCheckEmailConsents.value && canCheckSmsConsents.value)

// List of people to notify
export const contactPeople = ref<ConsentContactPerson[]>([])

// Watch to make sure that all checked consents are valid
watchEffect(() => {
  for (const addr of consents.value) {
    let consent: keyof typeof addr.consents
    for (consent in addr.consents) {
      if (addr.consents.hasOwnProperty(consent)) {
        if (!canCheckEmailConsents.value) {
          // Uncheck all Email consents
          addr.consents[consent].Email = false
        }
        if (!canCheckEmailConsents.value) {
          // Uncheck all Sms consents
          addr.consents[consent].Sms = false
        }
      }
    }
  }
})

export function resetConsentsForm() {
  lastStepSeen.value = 1
  gridUser.value = null
  gridAccessPoint.value = null
  consumptionAddresses.value = []
}

/**
 * Returns a getter/setter for agggregated DigitalLetter consent values
 * @param generatedId
 * @returns The computed object
 */
export function getDigitalLetterConsent(generatedId: string) {
  const digitalLetter = consents.value.find((o) => o.generatedId === generatedId)?.consents.DigitalLetter
  if (!digitalLetter) {
    throw Error('No DigitalLetter consents for generatedId ' + generatedId)
  }
  return computed({
    get(): boolean { return !!digitalLetter.Email && !!digitalLetter.Sms },
    set(val: boolean) {
      digitalLetter.Email = val
      digitalLetter.Sms = val
    }
  })
}

export function formatAddress(o: ConsumptionPlace): string {
  return `${o.streetName} ${o.houseNumber} ${o.complement ?? ''} ${o.complementBox ?? ''} ${o.floorNumber ? t('`common.identification.floor`') + ' ' + o.floorNumber : ''}`
}

/**
 * Returns the string keys of an enum
 * @param e An enum declaration
 * @returns
 */
export function enumStringKeys<T extends { [key: string]: any }>(e: T): Array<keyof T> {
  return Object.keys(e).filter((key) => !isNaN(Number(e[key])));
}

// TODO: use in IdentificationConfirmation.vue
export const addressFromConsumption = computed(() => {
  if (gridAccessPoint.value) {
    const address = gridAccessPoint.value.consumptionAddress
    let str = address.streetName
    if (address.houseNumber) {
      str += ' ' + address.houseNumber
    }
    if (address.complement) {
      str += ' ' + address.complement
    }
    if (address.complementBox) {
      str += ' ' + address.complementBox
    }
    if (address.floorNumber) {
      str += ` (${t(`common.identification.floor`)}: ${address.floorNumber})`
    }
    str += ', ' + address.zipCode
    str += ' ' + address.cityName
    return str
  }
  return ''
})

/**
 * Adds a space between the country code and the phone number.
 * Only works for mobile phones with format "+32xxxxxxxxx"
 * @param phone
 * @returns
 */
export function splitPhoneAndCountryCode(phone: string) {
  if (phone.startsWith('+32') && !phone.includes(' ')) {
    return phone.slice(0, 3) + ' ' + phone.slice(3)
  }
  return phone
}
