

























import { gridUser } from '@/composables/consents'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {
      gridUser,
    }
  },
})
