











































































































































































import {
  gridUser,
  token,
  consents,
  consumptionAddresses,
  formatAddress,
  getDigitalLetterConsent,
  splitPhoneAndCountryCode,
  canCheckEmailConsents,
  canCheckSmsConsents,
  canCheckDigitalLetter,
  currentAddress,
  goNextStep,
} from '@/composables/consents'
import { ConsentsForAddress } from '@/models'
import { GridUser } from '@/models/api/gridUser'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { ConsumptionPlace } from '@/models/api/consumptionPlace'
import ContactPeopleTable from './ContactPeopleTable.vue'

export default defineComponent({
  components: { ContactPeopleTable },

  setup() {
    const isEmailRequired = computed(() => !gridUser.value?.contact.mobile)
    const isMobileNumberRequired = computed(() => !gridUser.value?.contact.email)

    // Consents relative to selected address
    const currentConsents = computed(() =>
      consents.value.find((o) => o.generatedId === currentAddress.value?.generatedId),
    )

    // <select> data, gets/sets currentAddress
    const addressesOptions = computed(() =>
      consumptionAddresses.value.map((o) => ({ value: o.generatedId, text: formatAddress(o) })),
    )
    const selectedAddress = computed({
      get(): string {
        return currentAddress.value?.generatedId ?? ''
      },
      set(id: string) {
        currentAddress.value = consumptionAddresses.value.find((o) => o.generatedId === id)!
      },
    })

    // Used to display the error message next to the save button
    const isFormValid = ref(true)
    watch(
      gridUser,
      () => {
        // Automatically clear when the gridUser is modified
        isFormValid.value = true
      },
      { deep: true },
    )

    return {
      observer: ref(), // ref to ValidationObserver
      saving: false,
      loading: true,
      loadingError: false,
      isEmailRequired,
      isMobileNumberRequired,
      gridUser,
      canCheckEmailConsents,
      canCheckSmsConsents,
      canCheckDigitalLetter,
      currentAddress,
      addressesOptions,
      selectedAddress,
      currentConsents,
      isFormValid,
      formatAddress,
      getDigitalLetterConsent,
    }
  },

  methods: {
    async savePreferences() {
      this.isFormValid = true
      this.isFormValid = await this.observer.validate()
      if (!this.isFormValid) {
        return
      }
      this.saving = true
      try {
        // Email + mobile
        await Promise.all([
          this.$api.put(
            'api/ConsentsForm/grid-user/contact-information',
            {
              email: gridUser.value?.contact.email,
              mobileNumber: gridUser.value?.contact.mobile,
            },
            {
              headers: {
                Authorization: token.value,
              },
            },
          ),
          // Consents
          this.$api.post(
            'api/ConsentsForm/consents',
            {
              consentsForAddresses: consents.value,
              clientContactInformation: {
                email: gridUser.value?.contact.email,
                mobileNumber: gridUser.value?.contact.mobile,
                fullName: gridUser.value?.fullName,
              },
            },
            {
              headers: {
                Authorization: token.value,
              },
            },
          ),
        ])

        goNextStep()
      } catch (e) {
        // TODO: afficher message d'erreur
      } finally {
        this.saving = false
      }
    },
  },

  async mounted() {
    this.loading = true
    this.loadingError = false

    // Get GridUser with stakeholderId
    try {
      gridUser.value = (
        await this.$api.get<GridUser>(`api/ConsentsForm/grid-user`, {
          headers: {
            Authorization: token.value,
          },
        })
      ).data
      gridUser.value.contact.mobile = gridUser.value.contact.mobile
        ? splitPhoneAndCountryCode(gridUser.value.contact.mobile)
        : ''

      // Get Consumption Addresses (GridAccessPoint)
      consumptionAddresses.value = (
        await this.$api.get<ConsumptionPlace[]>(`api/ConsentsForm/consumption-places`, {
          headers: {
            Authorization: token.value,
          },
        })
      ).data

      // Get existing consents
      consents.value = (
        await this.$api.get<ConsentsForAddress[]>(`api/ConsentsForm/consents`, {
          headers: {
            Authorization: token.value,
          },
        })
      ).data
    } catch (e) {
      console.error(e)
      this.loadingError = true
    } finally {
      this.loading = false
    }
  },
})
