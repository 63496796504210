























































































import {
  addressFromConsumption,
  canContinueForm,
  gridAccessPoint,
  token,
  eanCode,
  lastFourMeterNumbers,
  goNextStep,
} from '@/composables/consents'
import { GridAccessPoint } from '@/models'
import { defineComponent, ref } from '@vue/composition-api'
import { chatService } from '@/services'

export default defineComponent({
  setup() {
    return {
      eanCode,
      lastFourMeterNumbers,
      identified: false,
      isFindEanModalOpen: false,
      isFindMeterNumberModalOpen: false,
      errorMessage: null as string | null,
      recaptcha: null as string | null,
      gridAccessPoint,
      addressFromConsumption,
      authenticating: false,
    }
  },

  methods: {
    openChat() {
      chatService.openChat()
    },

    async authenticate() {
      this.authenticating = true
      try {
        const res = await this.$api.get<{ token: string; gridAccessPoint: GridAccessPoint }>(
          `api/ConsentsForm/authenticate?eanCode=${this.eanCode}&lastFourMeterNumbers=${this.lastFourMeterNumbers}`,
          {
            headers: {
              'g-recaptcha-response': this.recaptcha!,
            },
          },
        )
        gridAccessPoint.value = res.data.gridAccessPoint
        token.value = res.data.token
        // canContinueForm.value = true
        this.identified = true
        goNextStep()
      } catch (error) {
        if (error.response?.status >= 400) {
          this.errorMessage = error.response.data.danger[0]
        }
      } finally {
        this.authenticating = false
      }
    },
  },
})
