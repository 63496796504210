




























import { defineComponent, ref } from '@vue/composition-api'
import { StepDefinition } from '@/models'
import ConsentsAuthentication from '@/components/steps/consents/ConsentsAuthentication.vue'
import ConsentsPreferences from '@/components/steps/consents/ConsentsPreferences.vue'
import ConsentsConfirmation from '@/components/steps/consents/ConsentsConfirmation.vue'
// import StepActions from '@/components/shared/StepActions.vue'
import { canContinueForm, currentStep, lastStepSeen, goToStep, goNextStep, resetConsentsForm } from '@/composables/consents'

const stepDefinitions: StepDefinition[] = [
  new StepDefinition('identification', 1),
  new StepDefinition('preferences', 2),
  new StepDefinition('confirm', 3),
]

export default defineComponent({
  components: {
    ConsentsAuthentication,
    ConsentsPreferences,
    ConsentsConfirmation,
    // StepActions,
  },

  setup() {
    return {
      stepDefinitions,
      canContinueForm,
      showStepActions: true,
      lastStepSeen,
      currentStep,
      goToStep,
      goNextStep,
    }
  },

  methods: {
    resetForm() {
      //
    },
    getStepComponent(): number {
      return this.currentStep + 1
    },
    goPreviousStep() {
      this.goToStep(this.currentStep - 1)
    },
    clickOnStep(step: number) {
      if (step > lastStepSeen.value) {
        return
      }
      this.currentStep = step
      if (step === 1) {
        resetConsentsForm()
      }
    },
  },

  beforeCreate() {
    document.title = this.$t('consents.formTitle').toString()
  },
  created() {
    const me = this;
    me.$api.get(`maintenance/consent`)
      .then((response) => {
        if (response.data.status === 'active') {
          me.$router.push({ path: '/maintenance' })
        }
      });
  }
})
